<template>
  <section class="change--pass-wrapper">
    <div class="title--text">
      {{ $t('loginRegister.changePass') }}
    </div>
    <form @submit.prevent="changePassword">
      <b-alert
        :variant="messageType"
        :show="messageShow"
        @dismissed="messageShow = false"
        dismissible
        >{{ message }}
      </b-alert>
      <div class="input--wrapper">
        <div class="form--group" :class="{ 'has-error': validation.hasError('oldPassword') }">
          <div class="left--col">
            <label for="old-pass">
              <span>{{ $t('loginRegister.oldPassword') }}</span>
            </label>
          </div>
          <div class="right--col">
            <div class="input--group">
              <input
                :class="{ 'has-error': validation.hasError('oldPassword') }"
                id="old-pass"
                v-model="oldPassword"
                :placeholder="$t('profile.placeholder.oldPass')"
                class="basic--input password--input"
                :type="showOldPass ? 'text' : 'password'"
              />
              <div class="append--right" @click="() => (showOldPass = !showOldPass)">
                <v-icon v-if="showOldPass">mdi-eye</v-icon>
                <v-icon v-else>mdi-eye-off</v-icon>
              </div>
            </div>
            <span class="val-error">{{ validation.firstError('oldPassword') }}</span>
          </div>
        </div>
        <div class="form--group" :class="{ 'has-error': validation.hasError('password') }">
          <div class="left--col">
            <label for="new-pass">
              <span>{{ $t('loginRegister.newPassword') }}</span>
            </label>
          </div>
          <div class="right--col">
            <div class="input--group">
              <input
                :class="{ 'has-error': validation.hasError('password') }"
                id="new-pass"
                v-model="password"
                :placeholder="$t('profile.placeholder.newPass')"
                class="basic--input password--input"
                :type="showNewPass ? 'text' : 'password'"
              />
              <div class="append--right" @click="() => (showNewPass = !showNewPass)">
                <v-icon v-if="showNewPass">mdi-eye</v-icon>
                <v-icon v-else>mdi-eye-off</v-icon>
              </div>
            </div>
            <span class="val-error">{{ validation.firstError('password') }}</span>
          </div>
        </div>
        <div class="form--group" :class="{ 'has-error': validation.hasError('confirmPassword') }">
          <div class="left--col">
            <label for="confirm-pass">
              <span>{{ $t('loginRegister.confirmPass') }}</span>
            </label>
          </div>
          <div class="right--col">
            <div class="input--group">
              <input
                :class="{ 'has-error': validation.hasError('confirmPassword') }"
                id="confirm-pass"
                v-model="confirmPassword"
                :placeholder="$t('profile.placeholder.confirm')"
                class="basic--input password--input"
                :type="showConfirmPass ? 'text' : 'password'"
              />
              <div class="append--right" @click="() => (showConfirmPass = !showConfirmPass)">
                <v-icon v-if="showConfirmPass">mdi-eye</v-icon>
                <v-icon v-else>mdi-eye-off</v-icon>
              </div>
            </div>
            <span class="val-error">{{ validation.firstError('confirmPassword') }}</span>
          </div>
        </div>
      </div>
      <div class="main--btn">
        <button class="btn btn-primary" type="submit">
          {{ $t('loginRegister.changePass') }}
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';

export default {
  mixins: [HelperMixin],
  components: {},
  data: () => ({
    oldPassword: '',
    password: '',
    confirmPassword: '',
    messageShow: false,
    loading: false,
    showOldPass: false,
    showNewPass: false,
    showConfirmPass: false,
  }),
  computed: {
    ...mapState({
      message: (state) => state.global.message,
      messageType: (state) => state.global.messageType,
    }),
  },
  validators: {
    oldPassword(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.password.required'))
        .minLength(6, this.$i18n.t('errors.loginRegister.password.min', { minChar: 6 }))
        .maxLength(255, this.$i18n.t('errors.loginRegister.password.max', { maxChar: 255 }));
    },
    password(value) {
      return Validator.value(value)
        .required(this.$i18n.t('errors.loginRegister.password.required'))
        .minLength(8, this.$i18n.t('errors.loginRegister.password.min', { minChar: 8 }))
        .maxLength(255, this.$i18n.t('errors.loginRegister.password.max', { maxChar: 255 }));
    },
    'confirmPassword, password': function (confirmPassword, password) {
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword)
          .required(this.$i18n.t('errors.loginRegister.confirmPass.required'))
          .match(password, this.$i18n.t('errors.loginRegister.confirmPass.notMatched'));
      }
    },
  },
  methods: {
    async changePassword() {
      const isValid = await this.$validate();
      console.log('is valid ga', isValid);
      if (isValid) {
        try {
          this.loading = true;
          let requestData = {
            old: this.cleanInput(this.oldPassword),
            password: this.cleanInput(this.password),
          };
          let data = await this.$store.dispatch('v2/profile/changePassword', requestData);
          if (data.error) {
            this.$store.commit('global/SET_ALERT_MESSAGE', {
              message: data.error,
              messageType: 'danger',
            });
            this.messageShow = true;
          } else {
            this.$swal('Success', this.$t('profile.changePassSuccess'), 'success');
            this.$store.dispatch('global/fetch');
            this.oldPassword = '';
            this.password = '';
            this.confirmPassword = '';
            await this.validation.reset();
          }
        } finally {
          this.loading = false;
        }
      }
    },
  },
  mounted() {},
};
</script>
